import { BrowserRouter, Routes, Route } from "react-router-dom";
import DetailsPage from "./DetailsPage";
import Header from "./Header";
import Home from "./Home";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path='/details/:id' element={<DetailsPage />}></Route>
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
