import GridContainer from "./commonComponents/GridContainer";
import SubHeader from "./commonComponents/SubHeader";
import { EVENTS } from "./utils/Constants";
import sample from "./utils/images/aims.jpg";
import { useNavigate } from "react-router-dom";

const EventCard = ({ event }) => {
  const navigate = useNavigate();

  const handleReadMore = (e) => {
    navigate(`/details/${e?.id}`);
  }

  return (
    <div className="text-white rounded-lg px-2 pb-4 pt-2 h-80 relative cursor-pointer transition duration-500 hover:scale-105 border-sea-green border bg-sea-green"onClick={(e) => {handleReadMore(event)}}>
      <div className="w-full h-70per object-cover">
        <img src={event?.images?.length > 0 ? event?.images[0] : sample} alt="event" className="rounded-lg w-full h-full" />
      </div>
      <div className="text-center font-semibold line-clamp max-h-16 my-4">{event?.title}</div>
      <div className="border border-sea-green rounded-full w-24 h-8 text-center float-right text-sm font-semibold py-2 absolute right-2.5 bottom-3">Read More...</div>
    </div>
  );
};

const Events = () => {
  return (
    <>
      <GridContainer>
        <div className="col-span-12 my-8" id={'4'}>
          <SubHeader header={"Glance of the activity conducted"} cls={'text-sea-green'}/>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-4">
            {EVENTS.map((event, index) => {
              return <EventCard key={`event-ngo-${index}`} event={event} />;
            })}
          </div>
        </div>
      </GridContainer>
    </>
  );
};

export default Events;
