import { Link } from "react-router-dom";
import GridContainer from "./commonComponents/GridContainer";
import logo from './utils/images/nirel_logo.jpg'

const Header = () => {
  
  return (
    <GridContainer bgColor={'bg-sea-green'}>
    <div className="col-span-12">
      <div className="flex flex-col md:flex-row md:items-end items-center justify-center  text-white py-10">
      <div className="text-4xl font-bold pt-4 pb-2">
        <Link to="/"><div className="flex flex-col md:flex-row items-center"><img src={logo} alt="nirel" width={200} height={'auto'} className="rounded-full mb-4 md:mb-0"/><div className="pl-4 pt-2.5 text-center">NIREL CHARITABLE TRUST</div></div></Link>
      </div>
        </div>  
      </div>
    </GridContainer>
  );
};

export default Header;
