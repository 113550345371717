import parse from "html-react-parser";
import SubHeader from "./SubHeader";

const Tile = ({ header, description }) => {
  return (
    <div className="commonTile">
    <SubHeader header={header}/>
      <div className="commonTile_description">
        {description ? parse(description) : ""}
      </div>
    </div>
  );
};

export default Tile;
