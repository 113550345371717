import GridContainer from "./commonComponents/GridContainer";
import SubHeader from "./commonComponents/SubHeader";
import Tile from "./commonComponents/Tile";
import ContactUs from "./ContactUs";
import Events from "./Events";
import { HEADER_OPTIONS, TREATMENTS } from "./utils/Constants";

const TreatmentCard = ({ treatment }) => {
  return (
    <div className="flex flex-col p-2 items-center text-center text-black">
      <img src={treatment?.logo} alt="card" className="w-12 h-10" />
      <div>{treatment?.name}</div>
    </div>
  );
};

const AimsAndObjectives = () => {
  return (
    <>
      <GridContainer>
        <div className="col-span-12 my-8" id={'3'}>
          <SubHeader header={"Aims & Objectives"} cls={'text-sea-green'}/>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-4">
          {TREATMENTS.map((treatment, index) => {
            return (
              <TreatmentCard treatment={treatment} key={`treatment-${index}`} />
            );
          })}
          </div>
        </div>
      </GridContainer>
    </>
  );
};

const Home = () => {
  return (
    <>
      <GridContainer>
        <div className="col-span-12 pt-4">
          {HEADER_OPTIONS.slice(0, 3).map((option, index) => {
            return (
              <div key={`tile-${index}`} id={`${index + 1}`}>
                <Tile
                  header={option?.title}
                  description={option?.description}
                />
              </div>
            );
          })}
           
        </div>
      </GridContainer>
      <AimsAndObjectives/>
      <Events />
      <ContactUs />
    </>
  );
};

export default Home;
