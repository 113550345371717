import GridContainer from "./commonComponents/GridContainer";
import SubHeader from "./commonComponents/SubHeader";
import { CONTACT } from "./utils/Constants";

const ContactCard = ({ data }) => {
  return (
    <div className="grid grid-cols-8 my-6">
      <div className="w-8 h-8 col-start-1">
        <img src={data?.image} alt={data?.title} className="object-cover" />
      </div>
      <div className="flex flex-col col-start-2 col-end-8 pl-4">
        <div className="text-sm font-semibold">{data?.title}</div>
        <div className="text-sm">
          {data?.id === 2 ? (
            <a href={`mailto:${data?.value}`}>{data?.value}</a>
          ) : data?.id === 1 ? (
            <a href={`tel:+91${data?.value}`}>{data?.value}</a>
          ) : (
            data?.value
          )}
        </div>
      </div>
    </div>
  );
};

const ContactUs = () => {
  return (
    <div className="py-10 bg-white" id={'5'}>
      <div className="h-37-5rem w-full relative">
        <div className="w-full h-24 absolute left-0 right-0 top-2/4 "></div>
        <GridContainer>
          <div className="col-span-12">
            <SubHeader header={"Contact Us"} />
            <div className="max-w-full sm:max-w-sm h-auto w-full md:w-1/2 bg-sea-green p-8 md:rounded-tr-3xl md:rounded-bl-3xl absolute left-1/2 -translate-x-1/2 top-1/4 shadow-lg text-white -mt-8">
              <div>Nirel Charitable Trust</div>
              {CONTACT.map((key, index) => {
                return <ContactCard key={`contact-${index}`} data={key} />;
              })}
            </div>
          </div>
        </GridContainer>
      </div>
    </div>
  );
};

export default ContactUs;
